var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"shareholderObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Język","label-for":"v-language-select"}},[_c('validation-provider',{attrs:{"name":"Język","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},on:{"input":_vm.fetchByLocale},model:{value:(_vm.form.lang),callback:function ($$v) {_vm.$set(_vm.form, "lang", $$v)},expression:"form.lang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tytuł zdjęcia","label-for":"v-image-title"}},[_c('validation-provider',{attrs:{"name":"Tytuł zdjęcia","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-image-title","placeholder":"Tytuł zdjęcia","disabled":!_vm.form.lang},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Zdjęcie nagłówkowe","label-for":"v-title-image"}},[_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('title_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie nagłówkowe","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.title_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.title_image.file),expression:"files.title_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie nagłówkowe"},domProps:{"value":(_vm.files.title_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.title_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.title_image),expression:"files.title_image"}],attrs:{"type":"hidden","name":"Zdjęcie nagłówkowe"},domProps:{"value":(_vm.files.title_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "title_image", $event.target.value)}}}),(_vm.showImagesModal.title_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.title_image},on:{"closeModal":function($event){return _vm.toggleShowModal('title_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'title_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.title_image)?_c('ImagePreview',{attrs:{"image":_vm.files.title_image},on:{"deleteImage":function () { return _vm.handleDelete('title_image'); }}}):_vm._e()],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Treść")]),_c('b-form-group',{attrs:{"label":"Zdjęcie na stronie","label-for":"v-site-image"}},[_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('site_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie na stronie","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.site_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.site_image.file),expression:"files.site_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie na stronie"},domProps:{"value":(_vm.files.site_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.site_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.site_image),expression:"files.site_image"}],attrs:{"type":"hidden","name":"Zdjęcie na stronie"},domProps:{"value":(_vm.files.site_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "site_image", $event.target.value)}}}),(_vm.showImagesModal.site_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.site_image},on:{"closeModal":function($event){return _vm.toggleShowModal('site_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'site_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.site_image)?_c('ImagePreview',{attrs:{"image":_vm.files.site_image},on:{"deleteImage":function () { return _vm.handleDelete('site_image'); }}}):_vm._e()],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Załącznik do pobrania","label-for":"v-site-attachment"}},[_c('validation-provider',{attrs:{"name":"Załącznik","rules":"size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"v-site-attachment","browse-text":"Wybierz załącznik (opcjonalnie)","placeholder":_vm.form.attachment_name || 'Nie wybrano załącznika',"drop-placeholder":"Przeciągnij tu załącznik","accept":".zip, .rar, .pdf, .doc, .docx, .xls, .xlsx","disabled":!_vm.form.lang},model:{value:(_vm.form.attachment),callback:function ($$v) {_vm.$set(_vm.form, "attachment", $$v)},expression:"form.attachment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"attachment-btns-container"},[(_vm.form.attachment_url)?_c('b-button',{staticClass:"btn mb-2",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.downloadFile}},[_vm._v(" Pobierz ")]):_vm._e(),(_vm.form.attachment)?_c('b-button',{staticClass:"btn mb-2",attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.removeFile}},[_vm._v(" Usuń ")]):_vm._e()],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Nagłówek"}},[_c('validation-provider',{attrs:{"name":"Nagłówek","rules":"required|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Nagłówek","rows":"3","disabled":!_vm.form.lang},model:{value:(_vm.form.header),callback:function ($$v) {_vm.$set(_vm.form, "header", $$v)},expression:"form.header"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{staticClass:"quill-editor-container",attrs:{"label":"Treść"}},[_c('validation-provider',{attrs:{"name":"Treść","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOption,"disabled":!_vm.form.lang},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary","disabled":!this.form.lang}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }