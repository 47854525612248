<template>
  <b-card class="p-1" ref="card">
    <form-component
      :page="shareholdersCommunication"
      @onSubmit="onSubmit"
      @fetchByLocale="fetchShareholdersCommunication" />
  </b-card>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';
import FormComponent from '@/components/pages/shareholders-communication/FormComponent';
import { show, submit } from '@/http/static-pages/shareholders-communication';
import { convertFromURLToFile } from '@/constants/utils/file';
import { createBasicPageForm } from '@/constants/utils/form';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      shareholdersCommunication: {
        title: '',
        header: '',
        value: '',
        title_image: null,
        site_image: null,
        attachment: null,
        attachment_url: '',
        attachment_name: null,
      },
    };
  },

  methods: {
    fetchShareholdersCommunication(lang = 'pl') {
      show(lang)
        .then(async ({ data: { data } }) => {
          if (!data) return;

          data.attachment_url = data.attachment;
          data.attachment = data.attachment ? await convertFromURLToFile(data.attachment) : null;

          this.shareholdersCommunication = data;
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    onSubmit(data) {
      const form = createBasicPageForm(data);

      form.append('header', data.header);
      form.append('site_image', data.site_image.file, data.site_image.fileName);
      if (data.attachment) form.append('attachment', data.attachment);

      submit(form)
        .then(() => {
          this.fetchShareholdersCommunication(form.lang);
          this.showSuccessNotification('Dane zapisane', 'Strona zostałą zapisana.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisaniem danych',
            'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
  },
};
</script>
