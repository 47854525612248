<template>
  <validation-observer ref="shareholderObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>
        <b-form-group label="Język" label-for="v-language-select">
          <validation-provider #default="{ errors }" name="Język" rules="required">
            <v-select
              id="v-language-select"
              label="value"
              placeholder="Wybierz język"
              v-model="form.lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="languages"
              @input="fetchByLocale" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Tytuł zdjęcia" label-for="v-image-title">
          <validation-provider #default="{ errors }" name="Tytuł zdjęcia" rules="required|max:150">
            <b-form-input id="v-image-title" placeholder="Tytuł zdjęcia" v-model="form.title" :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Zdjęcie nagłówkowe" label-for="v-title-image">
          <b-button @click="toggleShowModal('title_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie nagłówkowe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.title_image" type="hidden" name="Zdjęcie nagłówkowe" v-model="files.title_image.file" />
            <input v-else type="hidden" name="Zdjęcie nagłówkowe" v-model="files.title_image" />

            <ImageSelector
              v-if="showImagesModal.title_image"
              :files="filesArray"
              :show-modal="showImagesModal.title_image"
              @closeModal="toggleShowModal('title_image')"
              @submit="(fileList) => handleFile(fileList[0], 'title_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.title_image"
            :image="files.title_image"
            @deleteImage="() => handleDelete('title_image')" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Treść</h4>
        <b-form-group label="Zdjęcie na stronie" label-for="v-site-image">
          <b-button @click="toggleShowModal('site_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie na stronie" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.site_image" type="hidden" name="Zdjęcie na stronie" v-model="files.site_image.file" />
            <input v-else type="hidden" name="Zdjęcie na stronie" v-model="files.site_image" />

            <ImageSelector
              v-if="showImagesModal.site_image"
              :files="filesArray"
              :show-modal="showImagesModal.site_image"
              @closeModal="toggleShowModal('site_image')"
              @submit="(fileList) => handleFile(fileList[0], 'site_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.site_image"
            :image="files.site_image"
            @deleteImage="() => handleDelete('site_image')" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Załącznik do pobrania" label-for="v-site-attachment">
          <validation-provider #default="{ errors }" name="Załącznik" rules="size:5000">
            <b-form-file
              id="v-site-attachment"
              v-model="form.attachment"
              browse-text="Wybierz załącznik (opcjonalnie)"
              :placeholder="form.attachment_name || 'Nie wybrano załącznika'"
              drop-placeholder="Przeciągnij tu załącznik"
              accept=".zip, .rar, .pdf, .doc, .docx, .xls, .xlsx"
              :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="attachment-btns-container">
          <b-button v-if="form.attachment_url" @click="downloadFile" variant="primary" class="btn mb-2" size="sm">
            Pobierz
          </b-button>
          <b-button v-if="form.attachment" @click="removeFile" variant="danger" class="btn mb-2" size="sm">
            Usuń
          </b-button>
        </div>
      </b-col>

      <b-col>
        <b-form-group label="Nagłówek">
          <validation-provider #default="{ errors }" name="Nagłówek" rules="required|max:250">
            <b-form-textarea
              v-model="form.header"
              id="textarea-default"
              placeholder="Nagłówek"
              rows="3"
              :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group class="quill-editor-container" label="Treść">
          <validation-provider #default="{ errors }" name="Treść" rules="required">
            <quill-editor v-model="form.value" :options="editorOption" :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          :disabled="!this.form.lang">
          Zapisz
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { toolbarSettings } from '@/constants/quill-editor';
import { getLanguages } from '@/constants/languages';

import ImagePreview from '@/components/shared/ImagePreview';
import ImageSelector from '@/components/shared/ImageSelector.vue';

export default {
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  components: {
    quillEditor,
    ImagePreview,
    ImageSelector,
  },

  data() {
    return {
      languages: getLanguages(),
      form: this.page,
      editorOption: {
        modules: {
          toolbar: toolbarSettings,
        },
      },
    };
  },

  created() {
    this.form.lang = {
      lang: 'pl',
      value: 'Polski',
    };
    this.setFileUploadData(['title_image', 'site_image'], ['title_image', 'site_image']);
    this.fetchByLocale();
  },

  watch: {
    async page(newValue) {
      await Promise.all([
        this.setImage(this.page.title_image, 'title_image'),
        this.setImage(this.page.site_image, 'site_image'),
      ]);
      const lang = this.form.lang;

      this.form = { ...newValue, lang: lang };
    },
  },

  methods: {
    onSubmit() {
      const data = {
        ...this.form,
        title_image: this.files.title_image,
        site_image: this.files.site_image,
        lang: this.form.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.shareholderObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    fetchByLocale(e) {
      this.$emit('fetchByLocale', this.form.lang.lang);
    },
    removeFile() {
      this.form.attachment = null;
      this.form.attachment_name = null;
      this.form.attachment_url = null;
    },
    downloadFile() {
      fetch(this.form.attachment_url).then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.setAttribute('download', this.form.attachment_name);
          a.setAttribute('href', url);
          a.click();
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.attachment-btns-container {
  display: flex;
  gap: 15px;
}
</style>
